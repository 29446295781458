export const environment = {
  name: 'staging',
  label: 'staging',
  ravenUrl: 'https://89a3f99af00f4a6f93f664c00fded718@sentry.klinikdev.fi/3',
  production: true,
  version: '2024-11-13',
  backendURL: 'https://proxy.klinikdev.fi',
  klinikBackendUrl: 'https://backend.klinikdev.fi',
  baseCookieDomain: 'klinikdev.fi',
  secureCookies: true,
  formUrl: 'https://dynaform.klinikdev.fi',
  features: {
    userManagement: true,
  },
  availableLanguages: ['fi', 'en', 'sv', 'pt', 'nl'],
  defaultLocale: 'fi',
  translationMissingMessage: {
    fi: 'käännös puuttuu',
    sv: 'översättning saknas',
    en: 'translation missing',
    de: 'Übersetzung fehlt',
    es: 'falta la traducción',
    'es-mx': 'falta la traducción',
    pt: 'tradução faltando',
    nl: 'vertaling ontbreekt',
  } as Record<string, string>,
  kip_url: 'http://127.0.0.1:5565',
};
