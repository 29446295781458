// @ts-nocheck
/**
 * Enquiry class in this file provides typing, getters and other helpers for enquiry objects.
 *
 * A loved child has many names...
 * Appointment request, message, contact, enquiry and list goes on...
 * They all refer to the request sent by a patient to Health professional.
 */

import { MESSAGE_STATES } from 'services/message.service';
import { getNestedValue } from '../getNestedValue';
import { shortUuid } from 'services/utils.service';

/**
 * Interface for Enquiry class
 */
export interface Enquiry {
  id: number;
  is_short_appointment: boolean;
  is_long_appointment: boolean;
  data: EnquiryData;
  organized_symptoms: { [key: string]: EnquiryOrganizedSymptom };
  age_in_years: number;
  age_in_months: number;
  nhs_number: string | null;
  created_at: string;
  updated_at: string;
  priority: number;
  state: MESSAGE_STATES;
  service: number;
  assigned_to: EnquiryAssignedTo;
  contact_phone_number: string;
  comments: Array<EnquiryComment>;
  appointment_type: number;
  data_hidden: boolean;
  pro_form_filler: boolean;
  has_diagnosis_feedback: boolean;
  has_case_feedback: boolean;
  emis_patient_id: number;
  nhs_number_bypass: boolean;
  pds_suggestion_confirmed?: boolean;
  patient_validation: {
    provider: string;
    is_valid: boolean;
    nhs_number_superseded: boolean;
    strong_auth: boolean;
    practice_code: string;
    practice_code_match: boolean;
    error_description: string | undefined;
  } | null;
  form_submitted_at?: string; // Very old enquiries might not have this field
  has_pending_external_forwards: boolean;
  has_timed_out_external_forwards: boolean;
  sent_today: boolean;
  unsuitable_times?: Array<{}>;
  flagged_by_professional?: boolean;
}

/**
 * PDSSuggestions are suggestions from the Personal Demographics Service (PDS) for the patient identity.
 */
export interface PDSSuggestion {
  date_of_birth: string; // Date of birth in ISO format
  first_name: string;
  is_sensitive: boolean;
  last_name: string;
  nhs_number: string;
  registered_practice_ods: string;
  postal_code?: string;
}

/**
 * Interface for Enquiry "header" data.
 *
 * This is intended to match the MessageHeaderSerializer in Messages backend,
 * which is used when serializing a list of messages.
 */
export interface EnquiryHeader {
  /** appointment duration type, eg. "short" or "long" */
  appointment_duration_type: string;

  appointment_duration_type_id: number;

  /** The UUID of this enquiry */
  appointment_request_uuid: string;

  appointment_sub_type: unknown;

  appointment_type: number;

  /** The professional user who this enquiry is assigned to */
  assigned_to: number;

  /** The date and time when this enquiry was imported into the Messages backend. */
  created_at: string;

  data_hidden: boolean;

  enlisted: unknown;

  enquiry_submitted_by: string;

  /** The first name of the patient. */
  firstname: string;

  flagged_by_professional: unknown;

  /** The date and time when this enquiry was submitted by the patient */
  form_submitted_at: string;

  forwarded: boolean;

  full_name: unknown;

  has_new_attachments: boolean;

  /** The integer id of the enquiry */
  id: number;

  /** The last name of the patient */
  lastname: string;

  /** The priority of this enquiry, as evaluated by the Medical Engine. */
  priority: number;

  /** The integer id of the service that this enquiry is for */
  service: number;

  /** The current state of this enquiry */
  state: number;

  /** The date and time when this enquiry was last updated */
  updated_at: string;
}

export interface EnquiryComment {
  id: number;
  /** ISO format date string of when the comment was created. */
  created_at: string;

  /**
   * ISO format date string of when the comment was updated.
   * If this is the same as created_at, then the comment has NOT been updated.
   * Use the 'updated' field for a shortcut for determining this.
   * */
  updated_at: string;

  /** Whether the comment has been updated or not. */
  updated: boolean;

  /** Id of the user who wrote the comment. */
  user_id: number;

  /** Name of the user who wrote the comment. */
  user_name: string;

  /** The actual comment. */
  content: string;

  /** If this is a transfer comment, from which service the patient case was transferred from. */
  service_from: string;

  /** If this is a transfer comment, to which service the patient case was transferred to. */
  service_to: string;

  /**
   * Indicates whether the comment can still be edited or not.
   * There is a period of some days from comment creation during which this is allowed.
   */
  editable_by_date: boolean;
}

export enum AuditLogCaseType {
  OPENED_MESSAGE = 0,
  CHANGED_STATE = 1,
  SENT_SMS = 4,
  READ_SMS = 5,
  UPLOADED_DOCUMENTS = 13,
  REMOVED_ATTACHMENT = 16,
  NEW_TIMED_SNOOZE_EVENT = 17,
  NEW_RECURRING_OPENING_HOURS = 18,
  DEACTIVATE_OPENING_HOURS = 19,
  ACTIVATE_OPENING_HOURS = 20,
  DELETE_SNOOZE_EVENT = 21,
  MEDICAL_DATA_MOVED_TO_RECORD = 22,
  SMS_MESSAGE_MOVED_TO_RECORD = 23,
  ATTACHMENT_MOVED_TO_RECORD = 24,
  CONNECTED_ENQUIRY_TO_PRS = 25,
  MOVED_TO_PATIENT_RECORD = 26,
  MOVED_AS_AN_ACTION = 27,
  MESSAGE_TRANSFER_EVENT = 36,
  VIEWED_AUDIT_LOGS = 37,
  SERVICE_UPDATE = 38,
  CREATE_COMMENT = 39,
  EDIT_COMMENT = 40,

  // Integrations
  BLACK_PEAR_TPP_ATTEMPT = 44,
}

export enum AuditLogCaseState {
  OPEN = 0,
  HANDLING = 1,
  CLOSED = 2,
}

export interface EnquiryRecentUpdateMessageEvent {
  id: number;
  created_at: string;
  type: AuditLogCaseType;
  actor: EnquiryAssignedTo;
  data: {
    state?: AuditLogCaseState;
    service_from?: number | null;
    service_to?: number | null;
    service_group_from?: number | null;
    service_group_to?: number | null;
    service_from_name?: string | null;
    service_to_name?: string | null;
  } | null;
}

interface EnquiryAssignedTo {
  first_name: string;
  last_name: string;
}

interface EnquirySymptom {
  parent: number;
  translations: {};
}

interface EnquiryOrganizedSymptom extends EnquirySymptom {
  children?: Array<EnquirySymptom>;
  displayName: string; // Set during Enquiry class construction
  id: number; // Set during Enquiry class construction
}

export interface EnquiryData {
  appointment_request_uuid: string;
  data_v2?: EnquiryDataV2;
  appointment_request: EnquiryAppointmentRequestV1;
  geolocation: EnquiryGeoLocation;
  personal_info: EnquiryPersonalInfoV1;
  symptom_information: EnquiySymptomInformation;
  diagnosis_suggestions: Array<{}>;
  additional_information: string;
  appointment_type_questions: Array<EnquiryAppointmentTypeQuestion>;
  open_questions?: Array<EnquiryOpenQuestion>;
}

interface EnquiryOpenQuestion {
  question: string;
  value: string;
}

interface EnquiryAppointmentTypeQuestion {
  answer: string;
  question: string;
}

interface EnquiySymptomInformation {
  pain: number;
  location: string;
  symptoms: Array<{}>;
  location_id: number;
  earlier_treatment: string;
  earlier_medication: string;
  other_current_issues: string;
}

interface EnquiryAppointmentRequestV1 {
  short: boolean;
  service: number;
  service_slug: string;
  timing_requests: Array<{}>;
  service_group_slug: string;
  appointment_duration_type: string;
  excluded_times_description: string;
  appointment_duration_type_id: number;
}

interface EnquiryPersonalInfoV1 {
  email: string;
  employer: string;
  enlisted;
  lastname: string;
  firstname: string;
  full_name: string;
  telephone: string;
  municipality: string;
  caretaker_info: EnquiryCareTakerInfoV1;
  contact_methods: Array<string>;
  occupational_health_patient: string;
}

interface EnquiryCareTakerInfoV1 {
  caretaker_role: string;
  caretaker_lastname: string;
  caretaker_firstname: string;
  caretaker_phone_number: string;
}

interface EnquiryGeoLocation {
  latitude: string;
  longitude: string;
}

interface EnquiryAppointmentSubType {
  id: number;
  translations: {};
}

interface EnquiryFreeText {
  free_text: string;
  free_text_questions: string;
}

export interface EnquiryDataV2 {
  pain: Array<{}>;
  symptoms: {};
  diagnosis: {};
  free_text: EnquiryFreeText;
  source_form: string;
  service_slug: string;
  contact_route: object;
  personal_info: EnquiryPersonalInfoV2;
  pro_form_data: {};
  multi_question: Array<EnquiryMultiQuestion>;
  timing_requests: {};
  general_medication: {};
  service_group_slug: string;
  appointment_request: EnquiryAppointmentRequestV2;
  body_parts: EnquiryBodyParts;
  symptom_duration: EnquirySymptomDuration;
  symptom_descriptions: EnquirySymptomDescriptions;
  severity_symptoms?: {
    priority: number;
    severity_symptoms: Array<EnquirySeveritySymptoms>;
    not_selected_severity_symptoms: Array<EnquirySeveritySymptoms>;
  };
  earlier_self_treatment: EnquiryEarlierSelfTreatment;
  earlier_other_treatment: EnquiryEarlierOtherTreatment;
  free_text_medication_and_treatment: {};
  free_text_patient_history_and_status: {};
  open_questions?: Array<EnquiryOpenQuestion>;
  pds_suggestions: Array<PDSSuggestion> | undefined;
}

interface EnquiryMultiQuestion {
  answer: string;
  question: string;
}

interface EnquiryEarlierOtherTreatment {
  exact_question: string;
  previous_other_treatment_boolean: boolean;
  previous_other_treatment_description: string;
}

interface EnquiryEarlierSelfTreatment {
  exact_question: string;
  previous_self_treatment_boolean: boolean;
  previous_self_treatment_description: string;
}

interface EnquiryAppointmentRequestV2 {
  v3: boolean;
  short: boolean;
  timing_requests: Array<{}>;
  appointment_type_id: number;
  appointment_sub_type: EnquiryAppointmentSubType;
  excluded_times_description: string;
}

interface EnquirySeveritySymptoms {
  id: number;
  symptom: EnquirySeveritySymptom;
}

interface EnquirySeveritySymptom {
  id: number;
  selected: boolean;
  condition_id: number;
  severity_level: number;
  translations: {};
}

interface EnquirySymptomDescriptions {
  [key: string]: string;
}

interface EnquiryBodyParts {
  location_id?: number;
  translations?: {};
}

interface EnquirySymptomDuration {
  duration_number: number;
  duration_unit: string;
}

interface EnquiryPersonalInfoV2 {
  age: number;
  sex: string;
  ssn: string | undefined;
  bsn: string | undefined;
  service: string;
  age_unit: string;
  age_number: number;
  enlisted: {};
  nhs_number: string;
  postal_code: string;
  telephone: string;
  firstname: string;
  lastname: string;
  full_name: string;
  email: string;
  date_of_birth: string;
  municipality: {};
  caretaker_info: {};
  dynamic_fields: EnquiryDynamicFields | undefined;
  street_address: string;
  contact_methods: Array<EnquiryContactMethod>;
  identity_provider: EnquiryIdentityProvider;
  nhs_number_bypass: boolean;
  patient_agreement: {};
  caretaker_info_present: boolean;
  optional_information_anonymous: {};
  optional_information_identifiable: {};
}

interface EnquiryDynamicFields {
  [key: string]: EnquiryDynamicField | {};
  translation_keys: EnquiryDynamicFieldTranslationKeys;
}

export interface EnquiryDynamicField {
  value: string;
  translations: {};
  show_copy_in_proui: boolean;
}

interface EnquiryDynamicFieldTranslationKeys {
  [key: string]: string | number;
  form_specification_id: number;
}

interface EnquiryIdentityProvider {
  provider: string;
  auth_time: string;
  identity_id: number;
  practice_code: string;
  strong_authentication: boolean;
  identity_proofing_level: string;
}

interface EnquiryContactMethod {
  id: number;
  translations: {};
}

export interface IdentifierAndTranslation {
  value: string;
  translationKey: string;
}

export class Enquiry {
  constructor(enquiry: {}, language: string) {
    if (!enquiry) {
      throw Error(
        `enquiry is a required parameter! Received ${enquiry} instead.`
      );
    }

    this.id = getNestedValue(enquiry, 'id');
    this.organized_symptoms = getNestedValue(enquiry, 'organized_symptoms');
    this.age_in_months = getNestedValue(enquiry, 'age_in_months');
    this.age_in_years = getNestedValue(enquiry, 'age_in_years');
    this.nhs_number = getNestedValue(enquiry, 'nhs_number');
    this.created_at = getNestedValue(enquiry, 'created_at');
    this.form_submitted_at = getNestedValue(enquiry, 'form_submitted_at');
    this.data = getNestedValue(enquiry, 'data');
    this.comments = getNestedValue(enquiry, 'comments');
    this.symptomDisplayNames = language;
  }

  public get dataV2(): EnquiryDataV2 | undefined {
    return getNestedValue(this.data, 'data_v2');
  }

  public get hasComments(): boolean {
    if (!this.comments) {
      return false;
    }

    if (Array.isArray(this.comments) && this.comments.length === 0) {
      return false;
    }

    return true;
  }

  // PERSONAL INFO ITEMS
  public get personalInfo(): EnquiryPersonalInfoV2 {
    return getNestedValue(this.dataV2, 'personal_info');
  }

  public get telephone(): string {
    return getNestedValue(this.personalInfo, 'telephone');
  }

  /**
   * Returns the default personal identifier based on the market.
   * @param market
   * @returns identifier as a string (e.g. ssn for FI, NHS number for UK, BSN for NL)
   *
   * Tip: The market can be fetched from SessionService's market property
   */
  public getDefaultIdentifier(
    market: string | null
  ): IdentifierAndTranslation | null {
    if (!market || market == '') {
      return null;
    }

    const nhsNumber: string = this.nhs_number ? this.nhs_number.toString() : '';
    const ssn: string = this.dataV2?.personal_info?.ssn;
    const bsn: string = this.dataV2?.personal_info?.bsn;

    const valuesByMarket = {
      uk: {
        value: nhsNumber,
        translationKey: 'nhsNumber',
      },
      fi: {
        value: ssn,
        translationKey: 'viestinAsiakasHenkilotunnus',
      },
      nl: {
        value: bsn,
        translationKey: 'patientBSN',
      },
      ie: null,
      staging: {
        value: nhsNumber,
        translationKey: 'nhsNumber',
      },
      testing: {
        value: nhsNumber,
        translationKey: 'nhsNumber',
      },
      local: {
        value: nhsNumber,
        translationKey: 'nhsNumber',
      },
    };

    const identifier: IdentifierAndTranslation | null = getNestedValue(
      valuesByMarket,
      market
    );

    return identifier;
  }

  public get name(): string {
    const firstName: string = getNestedValue(this.personalInfo, 'firstname');
    const lastName: string = getNestedValue(this.personalInfo, 'lastname');
    if (firstName || lastName) {
      return `${firstName || ''} ${lastName || ''}`;
    }

    return getNestedValue(this.personalInfo, 'full_name');
  }

  public get email(): string {
    return getNestedValue(this.personalInfo, 'email');
  }

  public get dateOfBirth(): string | null {
    return this.personalInfo?.date_of_birth ?? null; //TODO: This typing can be perhaps guaranteed by fixing the personalInfo typing
  }

  public get sex(): string {
    const sex = getNestedValue(this.personalInfo, 'sex');
    if (sex === 'female') {
      return $localize`:@@esitiedotNainen:Female`;
    }

    if (sex === 'male') {
      return $localize`:@@esitiedotMies:Male`;
    }

    return sex;
  }

  public get streetAddress(): string | null {
    return getNestedValue(this.personalInfo, 'street_address');
  }

  public get postalCode(): string | null {
    return getNestedValue(this.personalInfo, 'postal_code');
  }

  public get dynamicFields(): EnquiryDynamicFields | undefined {
    return this.personalInfo?.dynamic_fields;
  }

  // AILMENT LOCATION ITEMS
  /**
   * Body parts are returned as an empty object when none available.
   */
  public get bodyParts(): EnquiryBodyParts | undefined {
    return this.dataV2?.body_parts;
  }

  public get severitySymptoms(): EnquirySeveritySymptoms[] {
    return this.dataV2?.severity_symptoms?.severity_symptoms ?? [];
  }
  public get notSelectedSeveritySymptoms(): EnquirySeveritySymptoms[] {
    return this.dataV2?.severity_symptoms?.not_selected_severity_symptoms ?? [];
  }

  public get symptomDuration(): EnquirySymptomDuration {
    return getNestedValue(this.dataV2, 'symptom_duration');
  }

  public get symptomDurationNumber(): number {
    return getNestedValue(this.symptomDuration, 'duration_number');
  }

  public get symptomDurationUnit(): string {
    const durationUnit = getNestedValue(this.symptomDuration, 'duration_unit');
    switch (durationUnit) {
      case 'hours':
        return $localize`:@@description_symptomDurationHours:hours`;
      case 'days':
        return $localize`:@@description_symptomDurationDays:days`;
      case 'weeks':
        return $localize`:@@description_symptomDurationWeeks:weeks`;
      case 'months':
        return $localize`:@@description_symptomDurationMonths:months`;
      case 'years':
        return $localize`:@@description_symptomDurationYears:years`;
      default:
        return null;
    }
  }

  /**
   * VAS = Visual Analogue Scale (https://en.wikipedia.org/wiki/Visual_analogue_scale)
   * Standard scale used for measuring feeling of pain.
   */
  public get painVAS(): number {
    return getNestedValue(this.dataV2, 'pain');
  }

  public get symptomDescriptions(): EnquirySymptomDescriptions {
    return getNestedValue(this.dataV2, 'symptom_descriptions');
  }

  // MISCELLANEOUS ITEMS
  public get age(): string {
    if (!this.age_in_months && !this.age_in_years) {
      return null;
    }

    if (this.age_in_months && this.age_in_months < 12) {
      return `${this.age_in_months} ${$localize`:@@esitiedotKuukautta:months`}`;
    } else if (this.age_in_years) {
      return `${this.age_in_years} ${$localize`:@@esitiedotVuotias:years`}`;
    }
  }

  /**
   * Returns the complete UUID of the enquiry.
   */
  public get uuid(): string {
    return getNestedValue(this.data, 'appointment_request_uuid');
  }

  /**
   * Returns the first 8 characters of the UUID of the enquiry.
   */
  public get shortUuid(): string {
    return shortUuid(this.uuid);
  }

  public get previousOtherTreatment(): EnquiryEarlierOtherTreatment {
    return getNestedValue(this.dataV2, 'earlier_other_treatment');
  }

  public get hasPreviousOtherTreatment(): boolean {
    return (
      getNestedValue(
        this.previousOtherTreatment,
        'previous_other_treatment_boolean'
      ) !== null
    );
  }

  public get previousOtherTreatmentQuestion(): string {
    return getNestedValue(this.previousOtherTreatment, 'exact_question');
  }

  public get previousOtherTreatmentQuestionKeyword(): string {
    return $localize`:@@previousOtherTreatmentQuestionKeyword:Previous treatment`;
  }

  public get freeTextQuestionKeyword(): string {
    return $localize`:@@freeTextQuestionKeyword:Additional information`;
  }

  public get previousOtherTreatmentDescription(): string {
    const otherTreatmentDescription = getNestedValue(
      this.previousOtherTreatment,
      'previous_other_treatment_description'
    );

    if (!otherTreatmentDescription) {
      return $localize`:@@messagesDetailDescriptionNo:No`;
    }

    return otherTreatmentDescription;
  }

  public get previousSelfTreatment(): EnquiryEarlierSelfTreatment {
    return getNestedValue(this.dataV2, 'earlier_self_treatment');
  }

  public get hasPreviousSelfTreatment(): boolean {
    return (
      getNestedValue(
        this.previousSelfTreatment,
        'previous_self_treatment_boolean'
      ) !== null
    );
  }

  public get previousSelfTreatmentQuestion(): string {
    return getNestedValue(this.previousSelfTreatment, 'exact_question');
  }

  public get previousSelfTreatmentQuestionKeyword(): string {
    return $localize`:@@previousSelfTreatmentQuestionKeyword:Self-care`;
  }

  public get previousSelfTreatmentDescription(): string {
    const selfTreatmentDescription = getNestedValue(
      this.previousSelfTreatment,
      'previous_self_treatment_description'
    );

    if (!selfTreatmentDescription) {
      return $localize`:@@messagesDetailDescriptionNo:No`;
    }

    return selfTreatmentDescription;
  }

  public get multiQuestions(): Array<EnquiryMultiQuestion> {
    return getNestedValue(this.dataV2, 'multi_question');
  }

  public get hasMultiQuestions(): boolean {
    return this.multiQuestions && this.multiQuestions.length > 0;
  }

  /**
   * @deprecated Take advantage of the static typing for 'appointment_type_questions' instead.
   */
  public get appointmentTypeQuestions(): Array<EnquiryAppointmentTypeQuestion> {
    return getNestedValue(this.data, 'appointment_type_questions');
  }

  /**
   * @deprecated Take advantage of the static typing for 'appointment_type_questions' instead.
   */
  public get hasAppointmentTypeQuestions(): boolean {
    return (
      this.appointmentTypeQuestions && this.appointmentTypeQuestions.length > 0
    );
  }

  public get openQuestions(): Array<EnquiryOpenQuestion> {
    return getNestedValue(this.dataV2, 'open_questions');
  }

  /**
   * Returns the free_text component's question and answer.
   */
  public get freeText(): EnquiryFreeText | null {
    return getNestedValue(this.dataV2, 'free_text');
  }

  // SETTERS

  /**
   * Append each symptom in organized_symptoms with a display name and an id.
   *
   * Symptom displayName is a combination of translated symptom name truncated with translated child symptom names.
   * Pain symptom is a special case where VAS pain scale is included in the displayName as well.
   */
  private set symptomDisplayNames(language: string) {
    if (!this.organized_symptoms) {
      return;
    }

    // key of a symptom in organized_symptoms is it's id.
    for (const key in this.organized_symptoms) {
      const symptom = this.organized_symptoms[key];

      let displayName = getNestedValue(
        symptom,
        'translations',
        language,
        'name'
      );

      const symptomID = Number(key);
      const painSymptomID = 32; // Pain symptom id is 32
      if (symptomID === painSymptomID && this.painVAS) {
        displayName += `, VAS ${this.painVAS}`;
      }

      const children = getNestedValue(symptom, 'children');
      if (children) {
        const childNames = children.map((child: EnquirySymptom) =>
          getNestedValue(child, 'translations', language, 'name')
        );
        displayName += ` (${childNames.join(', ')})`;
      }

      symptom['displayName'] = displayName;
      symptom['id'] = symptomID; // Store id for easier later use...
    }
  }
}
