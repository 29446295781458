import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';
import { HttpMethod, HttpTools } from 'tools/httptools';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { LoadingStatus } from 'enums';
import { BehaviorSubject, interval, throwError, timer } from 'rxjs';
import {
  switchMap,
  catchError,
  takeWhile,
  tap,
  finalize,
  takeUntil,
} from 'rxjs/operators';

type TaskIdResponse = {
  task_id: string;
};

type GetReportResponse = {
  download_url?: string;
};

export enum ReportType {
  CASE_COUNTS = 'case-counts',
  PRODUCTIVITY = 'productivity',
  // Add other report types here
}

const POLLING_INTERVAL_MS = 1000 * 2; // 2 seconds
const POLLING_TIMEOUT_MS = 1000 * 60 * 5; // 5 minutes

@Injectable({
  providedIn: 'root',
})
export class DataReportsService {
  /* The current status of the polling process */
  public pollingStatus$ = new BehaviorSubject<LoadingStatus>(
    LoadingStatus.NONE
  );
  /* The current status of the report enqueueing process */
  public enqueueStatus$ = new BehaviorSubject<LoadingStatus>(
    LoadingStatus.NONE
  );

  /* A map of currently pending tasks, where the key is the task ID and the value is the report type */
  private pendingTasks: Record<string, ReportType> = {};

  /* The base URL to the reports endpoint. Add a report type to the path to complete it. */
  getUrl() {
    return `${environment.backendURL}/service-group/${this.sessionService.serviceGroupId}/reports`;
  }

  constructor(
    private http: HttpClient,
    private httpTools: HttpTools,
    private sessionService: SessionService,
    private utilsService: UtilsService
  ) {}

  queueReport(reportType: ReportType, startDate: string, endDate: string) {
    const url = `${this.getUrl()}/${reportType}/`;
    const headers = this.httpTools.prepareHeaders(HttpMethod.POST);
    const body = {
      start_date: startDate,
      end_date: endDate,
    };

    this.enqueueStatus$.next(LoadingStatus.LOADING);

    return this.http.post<TaskIdResponse>(url, body, { headers }).pipe(
      tap((response) => {
        this.pendingTasks[response.task_id] = reportType;
        this.enqueueStatus$.next(LoadingStatus.SUCCESS);
      }),
      catchError((error) => {
        this.enqueueStatus$.next(LoadingStatus.FAILED);
        return throwError(error);
      }),
      finalize(() => {
        if (this.enqueueStatus$.getValue() === LoadingStatus.LOADING) {
          this.enqueueStatus$.next(LoadingStatus.NONE);
        }
      })
    );
  }

  public async startPolling(taskId: string) {
    const reportType = this.pendingTasks?.[taskId];

    // Nothing to poll for
    if (!reportType) {
      this.pollingStatus$.next(LoadingStatus.FAILED);
      return;
    }

    // Status update
    this.pollingStatus$.next(LoadingStatus.POLLING);

    // Polling logic
    const polling$ = interval(POLLING_INTERVAL_MS).pipe(
      switchMap(() => this.getReport(taskId, reportType)),
      takeWhile((response) => response.status === 202, true),
      catchError((error) => {
        this.pollingStatus$.next(LoadingStatus.FAILED);
        return throwError(error);
      })
    );

    // Timeout logic
    const timeout$ = timer(POLLING_TIMEOUT_MS).pipe(
      switchMap(() => throwError(() => new Error('Polling timeout reached')))
    );

    // Subscription
    polling$.pipe(takeUntil(timeout$)).subscribe({
      next: (response) => {
        switch (response.status) {
          case 202:
            break;
          case 200:
            this.pollingStatus$.next(LoadingStatus.SUCCESS);
            this.downloadReport(reportType, response.body?.download_url);
            break;
          default:
            this.pollingStatus$.next(LoadingStatus.FAILED);
            break;
        }
      },
      error: (error) => {
        this.pollingStatus$.next(LoadingStatus.FAILED);
      },
    });
  }

  private downloadReport(reportType: ReportType, downloadUrl?: string) {
    const url = `${environment.backendURL}${downloadUrl}`;
    const headers = this.httpTools.prepareHeaders(HttpMethod.GET);
    return this.http
      .get(url, { headers, responseType: 'blob', observe: 'response' })
      .subscribe((resp) => {
        this.utilsService.downloadFile(
          resp.body!,
          'text/csv',
          `${reportType}.csv`
        );
      });
  }

  private getReport(taskId: string, reportType: ReportType) {
    const url = `${this.getUrl()}/${reportType}/?task_id=${taskId}`;
    const headers = this.httpTools.prepareHeaders(HttpMethod.GET);

    return this.http
      .get<GetReportResponse>(url, { headers, observe: 'response' })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
